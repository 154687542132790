<template>
    <div class="dashboard">
        <el-row :gutter="12">
            <el-col :span="6">
                <el-card shadow="always">
                    <div class="card-item">
                        <div class="icon">
                            <i class="el-icon-s-order"></i>
                        </div>
                        <div class="info">
                            <span>总订单数: {{statistics.orderTotal}}</span>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card shadow="always">
                    <div class="card-item">
                        <div class="icon">
                            <i class="el-icon-money"></i>
                        </div>
                        <div class="info">
                            <span>总金额: ￥ {{statistics.orderMoneyTotal}}</span>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card shadow="always">
                    <div class="card-item">
                        <div class="icon">
                            <i class="el-icon-money"></i>
                        </div>
                        <div class="info">
                            <span>今日收入: ￥ {{statistics.todayOrderMoney}}</span>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card shadow="always">
                    <div class="card-item">
                        <div class="icon">
                            <i class="el-icon-s-data"></i>
                        </div>
                        <div class="info">
                            <span>今日订单: {{statistics.todayOrder}}</span>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-row class="mt-20">
            <el-col :span="24">
                <el-card>
                    <div slot="header" class="clearfix">
                        <span>本周订单</span>
                    </div>
                    <div id="weekChart"></div>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    var echarts = require('echarts/lib/echarts');
    // 引入柱状图组件
    require('echarts/lib/chart/line')
    // 引入提示框和title组件
    require('echarts/lib/component/tooltip')
    require('echarts/lib/component/title')
    require('echarts/lib/component/markPoint')
    import {getData} from '@/api/dashboard'

    export default {
        name: "dashboard",
        data() {
            return {
                echarts: echarts,
                statistics: {
                    orderTotal: 0,
                    orderMoneyTotal: 0,
                    todayOrderMoney:0,
                    todayOrder:0
                },
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            async getData() {
                const res = await getData('statistics');
                if(res){
                    this.statistics = {
                        orderTotal: res.orderTotal,
                        orderMoneyTotal: res.orderMoneyTotal,
                        todayOrderMoney:res.todayOrderMoney,
                        todayOrder:res.todayOrder
                    };
                    this.drawLine()
                }
            },
            async drawLine() {
                // 基于准备好的dom，初始化echarts实例
                let myChart = echarts.init(document.getElementById('weekChart'));
                const res = await getData('week');
                const keyData = Object.keys(res.data)
                // eslint-disable-next-line no-unused-vars
                const ValueData = Object.values(res.data)
                // eslint-disable-next-line no-unused-vars
                var markArr = [],
                    temObj = null;
                ValueData.forEach(function (v, i) {
                    if (v > 50) {
                        temObj = {
                            value: v,
                            xAxis: i,
                            yAxis: v,
                            itemStyle: {
                                color: '#FF0B00'
                            },
                            label: v
                        }
                    } else {
                        temObj = {
                            value: v,
                            xAxis: i,
                            yAxis: v,
                            label: v
                        }
                    }
                    markArr.push(temObj)
                });
                // console.log(markArr)
                // 绘制图表
                myChart.setOption({
                    title: {text: ''},
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'category',
                        data: keyData,
                        boundaryGap: false,
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: [{
                        data: ValueData,
                        type: 'line',
                        smooth: true,
                        markPoint: {
                            symbol: 'roundRect',
                            symbolSize: 20,
                            itemStyle: {
                                color: '#00CD68'
                            },
                            data: markArr
                        },
                    }]
                });
            },
        },
        components: {}
    }
</script>
<style lang="stylus" scoped>
    #weekChart
        height 480px
        width 100%

    .mt-20
        margin-top 20px

    .el-card
        width 100%

    .card-item
        display flex
        align-items center

        .icon
            flex 0
            margin-right 20px

            i
                font-size 40px
</style>
<style>
    /**
     * 默认尺寸为 600px×400px，如果想让图表响应尺寸变化，可以像下面这样
     * 把尺寸设为百分比值（同时请记得为容器设置尺寸）。
     */
    .echarts {
        width: 100%;
        height: 100%;
    }
</style>